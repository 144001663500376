<template>
  <v-card color="secondary" class="pa-3" @click="permalink">
    <v-row>
      <v-col cols="4">
        <v-icon color="primary" size="72">mdi-skull</v-icon>
      </v-col>
      <v-col cols="8">
        <h3>{{ name }}</h3>
        <span class="body-2" style="text-transform: uppercase">{{
          source
        }}</span
        ><br />
        <span class="body-2">Last seen: {{ formatDate(seen) }}</span
        ><br />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { formatDate } from "../utils/common";
export default {
  name: "author-summary",
  data: () => ({
    formatDate: formatDate,
  }),
  props: {
    name: {
      type: String,
      default: "No results",
    },
    source: {
      type: String,
      default: "No results",
    },
    seen: {
      type: String,
      default: "No results",
    },
    link: {
      type: String,
      default: "",
    },
    author: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    permalink() {
      let routeData = this.$router.resolve({
        path:
          "/darkrecon/networks/" +
          this.author.network_id +
          "/sources/" +
          this.author.source_id +
          "/authors/" +
          this.author.author_id,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
