<template>
  <div>
    <v-fade-transition>
      <v-btn
        v-show="authors.length > 20"
        fab
        dark
        fixed
        bottom
        right
        outlined
        color="white"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-fade-transition>
    <div>
      <search-bar
        :submitHandler="submitSearch"
        placeholder="Enter a threat actor name"
        :startValue="startValue"
      >
        <help-button
          :title="title"
          :content="content"
          class="ml-6"
        ></help-button>
      </search-bar>
    </div>
    <div style="min-height: 800px" class="mt-10">
      <AuthorList :authors="authors" v-if="authorsFound" :loading="loading" />
      <div v-if="loading && authors.length < 19">
        <v-row>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div v-if="authorsNotFound">
        <v-row>
          <v-col cols="12"> Sorry, no results found. </v-col>
        </v-row>
      </div>
    </div>
    <infinite-loading
      :identifier="infiniteId"
      @infinite="infiniteHandler"
      v-if="authors.length > 19"
    >
      <!-- To add the skeleton loader, see: -->
      <!-- https://peachscript.github.io/vue-infinite-loading/guide/configure-load-msg.html#via-slot-sepcial-attribute -->
      <div slot="spinner">
        <v-row>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="card" height="124"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div slot="no-more" class="body-2 mt-2">&nbsp;</div>
      <div slot="no-results" class="body-2 mt-2">&nbsp;</div>
    </infinite-loading>
  </div>
</template>

<script>
import store from "../store/index";
import InfiniteLoading from "vue-infinite-loading";
import SearchBar from "@/components/SearchBar.vue";
import HelpButton from "@/components/HelpButton.vue";
import AuthorList from "@/components/AuthorList.vue";

export default {
  name: "ThreatActors",
  components: {
    SearchBar,
    HelpButton,
    InfiniteLoading,
    AuthorList,
  },
  data: () => ({
    title: `How to use this page`,
    content: `<p>Get started with a new threat actor search by searching for an exact threat actor name or partial match.<br/><br/>Ex: searching <span style="color:#E86051">"hackerm"</span> returns <span style="color:#E86051">"hackerman42"</span></p>`,
    busy: false,
    infiniteId: 1,
    startValue: "",
  }),
  beforeCreate() {
    store.commit("threatActors/clearState");
  },
  methods: {
    submitSearch: function (queryString) {
      let url = new URL(window.location);
      url.searchParams.set("q", queryString);
      window.history.replaceState({}, "", url.toString());

      this.infiniteId += 1;
      store.dispatch("threatActors/getAuthors", {
        key: "queryString",
        value: queryString,
      });
    },
    toTop: function () {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      if (this.queryString == "") {
        setTimeout(function () {
          $state.loaded();
          return;
        }, 200);
      }
      store.dispatch("threatActors/loadMore").then((result) => {
        if (result === null) {
          $state.loaded();
          return;
        }
        if (result.length > 0) {
          $state.loaded();
          return;
        }
        if (result.length === 0) {
          $state.complete();
          return;
        }
      });
    },
  },
  computed: {
    loading: function () {
      return store.state.threatActors.loading;
    },
    authors: function () {
      return store.state.threatActors.authors;
    },
    queryString: function () {
      return store.state.threatActors.queryString;
    },
    authorsFound: function () {
      return this.authors.length > 0;
    },
    authorsNotFound: function () {
      return (
        this.authors.length == 0 &&
        this.queryString !== "" &&
        store.state.threatActors.notFound == true
      );
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    const queryString = params.get("q");

    this.startValue = queryString;

    if (queryString) {
      this.submitSearch(queryString);
    }
  },
};
</script>

<style scoped>
.search-bar >>> .v-icon.v-icon.v-icon--link {
  min-width: 0px;
}

@media (max-width: 800px) {
  .search-bar >>> .v-icon.v-icon.v-icon--link {
    min-width: 100px;
  }
}
</style>
