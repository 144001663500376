<template>
  <v-row>
    <v-col v-for="(author, i) in authors" :key="i" cols="4">
      <author-summary
        :key="i"
        :name="author.author_name"
        :source="author.source_id"
        :seen="author.last_posted"
        :author="author"
      />
    </v-col>
    <v-col v-if="loading" cols="4">
      <span
        :key="i"
        v-for="(placeholder, i) in Array.from(
          Array(3 - (authors.length % 3)).keys()
        )"
      >
        <v-skeleton-loader type="card" height="124"></v-skeleton-loader
      ></span>
    </v-col>
  </v-row>
</template>

<script>
import AuthorSummary from "@/components/AuthorSummary.vue";

export default {
  name: "AuthorList",
  components: {
    AuthorSummary,
  },
  props: {
    authors: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
